import settings from '../settings';
import { Box, Link as ALink, Typography, useTheme } from '@mui/material';
import nikeBalls from '../assets/ball-net-sm.jpg';
import screenshot1 from '../assets/screenshot1.png';
import screenshot2 from '../assets/screenshot2.png';
import screenshot3 from '../assets/screenshot3.png';
import downloadAppStore from '../assets/download-app-store.svg';
import downloadPlayStore from '../assets/download-play-store.svg';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

const TopBox = styled(Box)(({ theme }) => ({
  zIndex: 1,
  textAlign: 'center',
  paddingLeft: 64,
  paddingRight: 64,
  paddingTop: 64,
  paddingBottom: 64,
  [theme.breakpoints.down('md')]: {
    paddingLeft: 32,
    paddingRight: 32,
    paddingTop: 32,
    paddingBottom: 32,
  },
}));

function HomePage() {
  return (
    <>
      <div
        style={{
          // height: '50vh',
          width: '100vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: `linear-gradient(45deg, ${settings.colors.blue}, ${settings.colors.lightBlue})`,
          overflow: 'hidden',
          position: 'relative',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            position: 'absolute',
            background: settings.colors.gold,
            height: 70,
            width: '200vw',
            transform: 'rotate(-30deg)',
            top: '45%',
          }}
        />
        <div
          style={{
            top: 'calc(45% + 100px)',
            position: 'absolute',
            background: settings.colors.gold,
            height: 20,
            width: '200vw',
            transform: 'rotate(-30deg)',
          }}
        />
        <TopBox>
          <Typography
            variant="h1"
            color="white"
            style={{ textShadow: `1px 1px 4px black` }}
          >
            Possess the Ball
          </Typography>
          <Typography color="white" variant="h4" style={{ marginBottom: 32 }}>
            A Beautiful, simple, soccer-stats tracking app
          </Typography>
          <Typography variant="p" color="white">
            A mobile app for tracking ball possession and other game stats.
            Built by coaches and players. Track goals, shots, shots on target,
            possession, fouls and more. From youth to pro, data is critical to
            understanding failure and success. Tracking and measuring progress
            is crucial to development. Possess the Ball is the beautiful,
            simple, soccer-stats tracking app you expect.
          </Typography>
          <Box style={{ paddingTop: 32 }}>
            <ALink
              href="https://www.apple.com/app-store/"
              color="inherit"
              underline="none"
              target="_blank"
            >
              <img
                src={downloadAppStore}
                alt="download-app-store"
                style={{ height: 48, marginRight: 32 }}
              />
            </ALink>
            <ALink
              href="https://play.google.com/store/apps"
              color="inherit"
              underline="none"
              target="_blank"
            >
              <img
                src={downloadPlayStore}
                alt="download-play-store"
                style={{ height: 48 }}
              />
            </ALink>
          </Box>
        </TopBox>
      </div>
      <div
        style={{
          height: '75vh',
          backgroundImage: `url(${nikeBalls})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          width: '100vw',
          overflowX: 'auto',
          overflowY: 'hidden',
        }}
      >
        <div
          style={{
            width: '100vw',
            minWidth: 1000,
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            height: '75vh',
          }}
        >
          {[screenshot1, screenshot2, screenshot3].map((screenshot) => (
            <img src={screenshot} alt="screenshot" style={{ height: '65vh' }} />
          ))}
        </div>
      </div>
      <div
        style={{
          width: '100vw',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          background: `linear-gradient(45deg, ${settings.colors.blue}, ${settings.colors.lightBlue})`,
          // background: settings.colors.blue,
          overflow: 'hidden',
          position: 'relative',
          padding: '64px 32px',
        }}
      >
        <ALink
          href="https://lale.io"
          color="inherit"
          underline="hover"
          style={{ color: 'white', marginRight: 16 }}
        >
          © 2024 Lale.io
        </ALink>
        <ALink
          color="inherit"
          underline="hover"
          style={{ color: 'white' }}
          component={Link}
          to="/privacy"
        >
          Privacy Policy
        </ALink>
      </div>
    </>
  );
}

export default HomePage;
