import { CircularProgress } from '@mui/material';
import React from 'react';
import logo from '../assets/ball256.png';
import settings from '../settings';

const AppLoading = ({ suspense = false }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: '100vh',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <div style={{ position: 'relative' }}>
        <div
          style={{
            borderRadius: '50%',
            marginBottom: 16,
            height: 140,
            width: 140,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            style={{
              width: 120,
              filter: `drop-shadow(0px 0px 2px ${settings.colors.blue})`,
            }}
            src={logo}
            alt="logos"
          />
        </div>
        {!suspense && (
          <CircularProgress
            size={180}
            thickness={1}
            color="secondary"
            style={{
              position: 'absolute',
              top: -20,
              left: -20,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default AppLoading;
