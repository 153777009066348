import tinycolor from 'tinycolor2';

const settings = {
  colors: {
    blue: '#012840',
    lightBlue: '#005082',
    lightBlueTransparent: tinycolor('#005082').setAlpha(0.1).toRgbString(),
    lightBlueActive: tinycolor('#005082').darken(10).toHexString(),
    superLightBlue: '#D1DFE8',
    gold: '#977530',
    lightGold: '#CC9E41',
    lightGoldTransparent: tinycolor('#CC9E41').setAlpha(0.1).toRgbString(),
    lightGoldActive: tinycolor('#CC9E41').darken(10).toHexString(),
    superLightGold: '#EDDFC5',
    dark: 'rgba(0, 0, 0, .8)',
    red: '#D62828',
  },
};

export default settings;
