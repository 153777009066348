import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './app.css';
import HomePage from './pages/HomePage';
import settings from './settings';
import { BrowserRouter as Router } from 'react-router-dom';
import { Route, Routes } from 'react-router';
import PrivacyPolicy from './pages/PrivacyPolicy';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: settings.colors.blue,
    },
    secondary: {
      main: settings.colors.gold,
    },
    error: {
      main: settings.colors.red,
    },
    background: {
      default: 'white',
    },
    subdued: {
      main: 'rgba(0, 0, 0, 0.87)',
    },
  },
  typography: {
    // fontFamily: 'ABeeZee, sans-serif',
    h1: {
      fontFamily: '"Bebas Neue", sans-serif',
      color: settings.colors.theoraNavyBlue,
      // fontWeight: 'bold',
      letterSpacing: 2,
    },
    h2: {
      fontFamily: '"Bebas Neue", sans-serif',
      color: settings.colors.theoraNavyBlue,
      // fontWeight: 'bold',
      letterSpacing: 2,
    },
    h3: {
      fontFamily: '"Bebas Neue", sans-serif',
      color: settings.colors.theoraNavyBlue,
      // fontWeight: 'bold',
      letterSpacing: 2,
    },
    h4: {
      fontFamily: '"Bebas Neue", sans-serif',
      color: settings.colors.theoraLightBlue,
      // fontWeight: 'bold',
      letterSpacing: 2,
    },
    h5: {
      fontFamily: '"Bebas Neue", sans-serif',
      color: settings.colors.theoraLightBlue,
      // fontWeight: 'bold',
      letterSpacing: 2,
    },
    h6: {
      fontFamily: '"Bebas Neue", sans-serif',
      color: settings.colors.theoraLightBlue,
      // fontWeight: 'bold',
      letterSpacing: 1,
    },
  },
});

function App() {
  return (
    <>
      <ThemeProvider theme={lightTheme}>
        <CssBaseline />
        <Router>
          <Routes>
            <Route exact path="/privacy" element={<PrivacyPolicy />} />
            <Route path="*" element={<HomePage />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </>
  );
}

export default App;
